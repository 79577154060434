import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/ga.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/gtm.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Nunito\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"nunito\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Bluesky_Logo.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/discord_icon_clyde_white_RGB.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Instagram_Glyph_White.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/threads-logo-white.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/TwitchGlitchWhite.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/x_logo.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/components/LocaleSwitcherSelect.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/components/NavDropdown.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/icon.svg")