'use client';

import React, { useState } from 'react';
import Link from 'next/link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

export interface MenuItem {
  title: string;
  route?: string;
  children?: MenuItem[];
}

interface Props {
  item: MenuItem;
}

export default function NavDropdown(props: Props) {
  const { item } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const menuItems = item?.children ? item.children : [];

  const toggle = () => {
    setIsOpen((old) => !old);
  };

  const transClass = isOpen ? 'flex' : 'hidden';

  return (
    <>
      <div className="relative">
        <button className="hover:text-primary"
          onClick={toggle} 
          // onMouseEnter={toggle}
        >
          {item.title}
          <FontAwesomeIcon size={'xs'} icon={isOpen ? faChevronUp : faChevronDown } className="ml-2 -mb-[1px] h-3" />
        </button>
        <div
          className={`absolute top-8 z-30 min-w-40 flex flex-col py-4 border border-main border-opacity-5 bg-white shadow-lg rounded-md ${transClass}`}
          onMouseLeave={toggle}
        >
          {menuItems.map((item: MenuItem) => (
            <Link
              key={item.route}
              className="px-4 py-1 hover:bg-primary bg-none hover:bg-opacity-50 text-black hover:text-creme"
              href={item?.route || ''}
              onClick={toggle}
            >
              {item.title}
            </Link>
          ))}
        </div>
      </div>
      {isOpen ? (
        <div
          className="fixed top-0 right-0 bottom-0 left-0 z-2"
          onClick={toggle}
        ></div>
      ) : (
        <></>
      )}
    </>
  );
}
