'use client';

import { ChangeEvent, ReactNode, useTransition } from 'react';
import { useRouter, usePathname } from '../../navigation';

type Props = {
  children: ReactNode;
  defaultValue: string;
  label: string;
};

export default function LocaleSwitcherSelect({
  children,
  defaultValue,
  label,
}: Props) {
  const router = useRouter();
  const [isPending, startTransition] = useTransition();
  const pathname = usePathname();

  function onSelectChange(event: ChangeEvent<HTMLSelectElement>) {
    const nextLocale = event.target.value;
    startTransition(() => {
      router.replace(pathname, { locale: nextLocale });
    });
  }

  return (
    <label
      className={'' + isPending && 'transition-opacity [&:disabled]:opacity-30'}
    >
      <label className="sr-only">{label}</label>
      <select
        className="bg-transparent border-0 focus:ring-0 rounded-lg pl-0 pr-8"
        defaultValue={defaultValue}
        disabled={isPending}
        onChange={onSelectChange}
      >
        {children}
      </select>
    </label>
  );
}
