import { Pathnames } from 'next-intl/navigation';

export const locales = ['en', 'de'] as const;

export const pathnames = {
  '/': '/',
  '/#projects': { en: '/#projects', de: '/#projekte' },
  '/#vision': { en: '/#vision', de: '/#vision' },
  '/#roadmap': { en: '/#roadmap', de: '/#roadmap' },
  '/#about': { en: '/#about', de: '/#über uns' },
  '/blog': '/blog',
  '/games/ams2': '/games/ams2',
  '/games/acc': '/games/acc',
  '/games/ac': '/games/ac',
  '/games/r3e': '/games/r3e',
  '/games/iracing': '/games/iracing',
  '/games/lmu': '/games/lmu',
} satisfies Pathnames<typeof locales>;

// Use the default: `always`
export const localePrefix = undefined;

export type AppPathnames = keyof typeof pathnames;
